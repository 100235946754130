//import DateFnsUtils from '@date-io/date-fns';
import {
  Breakpoint,
  Container,
  Grid,
  Icon,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import React, { Fragment, useContext } from 'react';
import { Account, Reminder, User } from '@earnenterprise/asc-models';
import { LanguageContext } from 'translation/languageContext';
import { BaseContainerAlerts } from 'fields/basecontainer_alerts';
import { BaseContainerButtons } from 'fields/basecontainer_buttons';
import { BaseContainerStatus } from 'fields/basecontainer_status';
import { BaseContainerReminder } from 'fields/basecontainer_reminder';
import { BaseContainerFunctions } from 'fields/basecontainer_functions';
//import svLocale from 'date-fns/locale/sv';

/**
 *
 */
interface Props {
  id?: string | number | null;
  type?: string;
  showReminders?: boolean;
  reminders?: Reminder[] | null;
  reminderUser?: User | null;
  reminderAccount?: Account | null;
  header: string;
  linkHeaders?: { header: string; fn: any }[];
  subheader?: string;
  submitButton?: boolean;
  submitText?: string;
  deleteText?: string;
  resetText?: string;
  onReset?: any;
  onDelete?: any;
  isSubmitting?: boolean;
  dirty?: boolean;
  error?: string;
  warning?: string;
  info?: string;
  size?: Breakpoint;
  status?: { opportunity: boolean; quote: boolean; order: boolean; agreement: boolean };
  buttons?: { name: string; fn: any; icon?: any }[];
  functions?: { name: string; fn: any; icon?: any }[];
  exportFunction?: any;
}

/**
 *
 * @param props
 */
export const BaseContainer: React.FC<Props> = ({
  header,
  linkHeaders,
  subheader,
  exportFunction,
  children,
  ...props
}) => {
  const { translate } = useContext(LanguageContext);

  return (
    <Container maxWidth={props.size}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: '16px' }}>
            <Grid container direction="row">
              {props.showReminders === true && (
                <BaseContainerReminder
                  id={props.id}
                  type={props.type}
                  reminders={props.reminders}
                  reminderAccount={props.reminderAccount}
                  reminderUser={props.reminderUser}
                />
              )}
              <Grid item>
                <Typography variant="h6">{header}</Typography>
                {subheader ? <Typography variant="body2">{subheader}</Typography> : null}
              </Grid>
              {!props.status && !props.functions && !linkHeaders && (
                <Grid item sm style={{ justifyContent: 'end' }}></Grid>
              )}
              <BaseContainerStatus status={props.status} />
              {linkHeaders && (
                <Grid item xs style={{ textAlign: 'end' }}>
                  {linkHeaders?.map((linkHeader, indexHeader) => (
                    <Fragment key={indexHeader}>
                      {indexHeader > 0 && ' / '}
                      <Link onClick={linkHeader.fn} underline="none" style={{ cursor: 'pointer' }}>
                        {linkHeader.header}
                      </Link>
                    </Fragment>
                  ))}
                  {exportFunction && (
                    <IconButton
                      size="medium"
                      title={translate('Export to Excel')}
                      onClick={exportFunction}
                    >
                      <Icon>file_copy</Icon>
                    </IconButton>
                  )}
                  {props.functions && (
                    <BaseContainerFunctions functions={props.functions} removeGrid={true} />
                  )}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: '16px' }}>
            <BaseContainerAlerts error={props.error} warning={props.warning} info={props.info} />
            <Grid container spacing={3}>
              {children}
            </Grid>
            {false && <BaseContainerButtons {...props} />}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BaseContainer;
